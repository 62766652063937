<template>
  <svg viewBox="0 0 15 15">
    <path class="st0"
      d="M0.5,4.5c0-2.2,1.8-4,4-4h5c2.2,0,4,1.8,4,4v5c0,2.2-1.8,4-4,4h-5c-2.2,0-4-1.8-4-4V4.5z" />
    <path class="st1"
      d="M9.7,1.2H4.3c-1.7,0-3.2,1.4-3.2,3.2v5.4c0,1.7,1.4,3.1,3.2,3.1h5.4c1.7,0,3.1-1.4,3.1-3.1V4.3
	C12.8,2.6,11.4,1.2,9.7,1.2z M4.3,0C1.9,0,0,1.9,0,4.3v5.4C0,12.1,1.9,14,4.3,14h5.4c2.4,0,4.3-1.9,4.3-4.3V4.3C14,1.9,12.1,0,9.7,0
	H4.3z" />
    <path class="st0"
      d="M10.5,7c0,1.9-1.6,3.5-3.5,3.5S3.5,8.9,3.5,7S5.1,3.5,7,3.5S10.5,5.1,10.5,7z" />
    <path class="st1"
      d="M7,9.3c1.3,0,2.3-1,2.3-2.3S8.3,4.7,7,4.7S4.7,5.7,4.7,7S5.7,9.3,7,9.3z M7,10.5c1.9,0,3.5-1.6,3.5-3.5
	S8.9,3.5,7,3.5S3.5,5.1,3.5,7S5.1,10.5,7,10.5z" />
    <path class="st2"
      d="M11.7,3.2c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C11.3,2.3,11.7,2.7,11.7,3.2z" />
  </svg>
</template>

<script>
export default {
  name: 'InstagramIcon',
};
</script>

<style lang="scss" scoped>

.st0 {
  fill: #4f4f4f;
}

.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}

.st2 {
  fill: #ffffff;
}
</style>