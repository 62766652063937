import Vue from 'vue';

Vue.directive('osg-pattern', {
  bind(el, binding, vnode) {
    const pattern = binding.value;

    if (pattern) {
      
      const handler = (event) => {

        let value = event.target.value;
        const isInsertedFromPaste = event.inputType === 'insertFromPaste';

        if (isInsertedFromPaste) {
         value = value.replace(/(\n+)/gm, ' ');
        }
        
        if (value && !pattern.test(value)) {
          event.target.value = isInsertedFromPaste ? null : value.substr(0, value.length - 1)
          vnode.componentInstance.onInput(event)
          
        } else if (isInsertedFromPaste) {
          event.target.value = value
          vnode.componentInstance.onInput(event)
        }
      }

      el.addEventListener('input', handler, false);

      if (binding.modifiers.numeric) {
        el.addEventListener('focusout', (event) => {
          if (event.target.value) {
            const parsedNumber = Number(event.target.value);
            event.target.value = isNaN(parsedNumber) ? null : parsedNumber
            vnode.componentInstance.onInput(event)
          }
        });
      }

    }
  }
})