import URI from 'urijs'
import axios from 'axios';
import { config } from '@/plugins/env.config'

async function retryRequest(error, attempt, callback, ...callbackArgs) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response ? error.response : error
    const status = response.status ? response.status : error.status

    if (status === 500 && attempt < maxAttempts) {
        await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
        return await callback(...callbackArgs, attempt + 1)
    }

    throw error
}

export async function getSponsorForms (sponsorSlug, attemptNumber = 1){
    const getSponsorFormUri = new URI(config.apiBaseUrl)
    getSponsorFormUri.segment('beneficiary-lookup')
    getSponsorFormUri.segment('v1')
    getSponsorFormUri.segment('sponsors')
    getSponsorFormUri.segment(sponsorSlug)
    getSponsorFormUri.segment('forms')

    const getTokenHeaders = {
        Authorization: `Bearer ${localStorage.getItem('clientToken')}`
    }

    try {
        return await axios.get(getSponsorFormUri.toString(), {
            headers: getTokenHeaders
        })
    } catch (error) {
        return retryRequest(error, attemptNumber, getSponsorForms, sponsorSlug)
    }
}

export async function searchBeneficiaries (sponsorSlug, payload, attemptNumber = 1){
    const searchBeneficiariesUri = new URI(config.apiBaseUrl)
    searchBeneficiariesUri.segment('beneficiary-lookup')
    searchBeneficiariesUri.segment('v1')
    searchBeneficiariesUri.segment('sponsors')
    searchBeneficiariesUri.segment(sponsorSlug)
    searchBeneficiariesUri.segment('search')

    const getTokenHeaders = {
        Authorization: `Bearer ${localStorage.getItem('clientToken')}`
    }

    try{
        return await axios.post(searchBeneficiariesUri.toString(), payload, {
            headers: getTokenHeaders
        })

    }catch(error){
        return retryRequest(error, attemptNumber, searchBeneficiaries, sponsorSlug, payload)
    }
}
