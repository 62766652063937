import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './plugins/vuex'
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n'
import './plugins/bugsnag'
import '@/styles/application.scss'
import Interceptors from '@/plugins/interceptors'
import '@/directives/osg-pattern';
import '@/directives/osg-date';

Vue.config.productionTip = false

const interceptors = Interceptors(store)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  interceptors,
  render: h => h(App)
}).$mount('#app')
