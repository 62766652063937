import moment from 'moment'
import arcService from '@/services/arc-api.service'

export default {
    authorizations: (state) => {
        return state.authorizations.map(authorization => {
            return {
                id: authorization.id,
                beneficiary: authorization.beneficiaryId,
                status: arcService.getStatus(authorization),
                statusSlug: authorization.status,
                amount: authorization.amount,
                currency: authorization.currency,
                amountAuthorized: authorization.currency ?
                    authorization.currency.concat(' ', authorization.amount.toLocaleString()) : '',
                productStatus: authorization.productStatus,
                iconConfiguration: arcService.getIcon(authorization.productStatus),
                statusConfiguration: arcService.getColor(authorization),
                type: arcService.getType(authorization.coverageType),
                coverageType: authorization.coverageType,
                date: moment(authorization.createdAt).format('DD/MM/YYYY HH:mm'),
                reimbursementId: authorization.reimbursementId,
                authorizationId: authorization.id,
                beneficiaryName: authorization.beneficiaryName,
                certificateNumber: authorization.beneficiaryMemberCode
            }
        })
    },
    page: (state) => state.page,
    authorizationLoading: (state) => state.authorizationLoading,
    authorizationCommentLoading: (state) => state.authorizationCommentLoading,
    authorizationPage: (state) => {
        const { page } = state;
        return { ...page, number: page.number + 1 }
    },
    authorizationForms: (state) => state.authorizationForms,
    authorizationFormsAndSteps: (state) => state.authorizationFormsAndSteps,
    sponsorConfigurations: (state) => state.sponsorConfigurations,
    currentForm: (state) => state.currentForm,
    currentStep: (state) => state.currentStep,
    currentCoverage: (state) => state.currentCoverage,
    formSteps: (state) => {
        if (state.currentForm) {
            return state.authorizationFormsAndSteps[state.currentForm].length
        }
        return 2
    },
    getError: (state) => state.error,
    files: (state) => state.files,
    inputs: (state) => state.inputs,
    stepError: (state) => state.stepError,
    sponsorSlug: (state) => state.sponsorSlug,
    countryCode: (state) => state.countryCode,
    currencyCode: (state) => state.currencyCode,
    generalBalance: (state) => state.beneficiary.generalBalance,
    getBeneficiary: (state) => {
        const { name, emailAddress, memberCode } = state.beneficiary;
        return { name, emailAddress, memberCode};
    },
    getBeneficiaryData: (state) => {
        const { name, emailAddress, memberCode, identityDocumentId, identityDocumentType } = state.beneficiary;
        return { name, emailAddress, memberCode, identityDocumentId, identityDocumentType};
    },
    reimbursementStatus: (state) => state.reimbursementStatus,
    claimType: (state) => state.claimType,
    coverageBalanceDetail: (state) => state.beneficiary.coverageCostSharings,
    listAuthorization: (state) => state.listAuthorization,
    authorization: (state) => {
        const filesAndInputs = {};
        if (Object.keys(state.authorization).length === 0) {
            return {}
        } else {
            filesAndInputs['files'] = state.authorization.isReimbursement ? state.authorization.reimbursementFiles : state.authorization.authorizationFiles;
            filesAndInputs['inputs'] = state.authorization.isReimbursement ? state.authorization.reimbursementInputs : state.authorization.authorizationInputs;
        }

        return {
            ...state.authorization,
            ...filesAndInputs,
            statusLabel: arcService.getStatus(state.authorization),
            iconConfiguration: arcService.getIcon(state.authorization.productStatus),
            statusConfiguration: arcService.getColor(state.authorization),
            type: state.authorization.coverageType && arcService.getType(state.authorization.coverageType) || '',
        }
    },
    beneficiaryCountryCode: (state) => state.beneficiary.nationalityCountryCode,
    isReimbursement: (state) => state.isReimbursement,
    currentAuthorizationFormCoverage: (state) => {
        const currentForm = state.currentForm
        const currentAuthorizationForm = state.authorizationForms.find(form => form.formSlug === currentForm);

        if(!currentAuthorizationForm){
            return null;
        }

        return currentAuthorizationForm.authorizationCoverage;
    },
    loadingFile: (state) => state.loadingFile,
    searchBeneficiarySponsorForms: (state) => state.searchBeneficiaries.sponsorForms,
    searchBeneficiaryResults: (state) => state.searchBeneficiaries.beneficiaries,
    beneficiaryInformation: (state) => state.beneficiaryInformation,
    selectedBeneficiary: (state) => state.searchBeneficiaries.selectedBeneficiary,
    beneficiarySearchCompleted: (state) => state.searchBeneficiaries.searchCompleted,
    sponsorConfiguration: (state) => state.sponsorConfiguration,
}
