import Vue from 'vue';
import URI from 'urijs'
import { config } from '@/plugins/env.config'
import { status, icons, types, orderBy, statusFiltersSecondAssessment, statusFiltersHospitalization } from '@/config/authorization'

async function getAuthorizationList(filters, attemptNumber = 1) {
  const getAuthorizationsUri = new URI(config.apiBaseUrl)
  getAuthorizationsUri.segment('arc')
  getAuthorizationsUri.segment('v1')
  getAuthorizationsUri.segment('beneficiaries')
  getAuthorizationsUri.segment(filters.beneficiaryId)
  getAuthorizationsUri.segment('authorization-summaries')
  getAuthorizationsUri.addSearch('from_date', filters.fromDate)
  getAuthorizationsUri.addSearch('to_date', filters.toDate)
  getAuthorizationsUri.addSearch('statuses', filters.statuses)
  getAuthorizationsUri.addSearch('coverages', filters.types)
  getAuthorizationsUri.addSearch('page', filters.page)
  getAuthorizationsUri.addSearch('size', filters.size)
  getAuthorizationsUri.addSearch('search_value', filters.searchValue)
  getAuthorizationsUri.addSearch('order_by', filters.orderBy)
  getAuthorizationsUri.addSearch('sort', filters.sort)

  try {
    return await Vue.prototype.$http.get(getAuthorizationsUri.toString())
  } catch (error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return getAuthorizationList(filters, attemptNumber + 1)
    }

    throw error
  }
  
}

async function getAuthorizationForm(sponsorSlug, attemptNumber = 1) {
  const getAuthorizationFormUri = new URI(config.apiBaseUrl)
  getAuthorizationFormUri.segment('arc')
  getAuthorizationFormUri.segment('v1')
  getAuthorizationFormUri.segment('sponsors')
  getAuthorizationFormUri.segment(sponsorSlug)
  getAuthorizationFormUri.segment('authorization-forms')

  try {
    return await Vue.prototype.$http.get(getAuthorizationFormUri.toString())
  } catch (error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return getAuthorizationForm(sponsorSlug, attemptNumber + 1)
    }

    throw error
  }
}

async function getSponsorConfiguration(sponsorSlug, attemptNumber = 1) {
  const getSponsorConfigurationUri = new URI(config.apiBaseUrl)
  getSponsorConfigurationUri.segment('arc')
  getSponsorConfigurationUri.segment('v1')
  getSponsorConfigurationUri.segment('sponsors')
  getSponsorConfigurationUri.segment(sponsorSlug)
  getSponsorConfigurationUri.segment('configuration')

  try {
    return await Vue.prototype.$http.get(getSponsorConfigurationUri.toString())
  } catch (error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return getSponsorConfiguration(sponsorSlug, attemptNumber + 1)
    }
    
    throw error    
  }
}

async function sendReimbursement(payload, attemptNumber = 1) {
  const sendReimbursementUri = new URI(config.apiBaseUrl)
  sendReimbursementUri.segment('arc')
  sendReimbursementUri.segment('v1')
  sendReimbursementUri.segment('reimbursements')

  try {
    return await Vue.prototype.$http.post(sendReimbursementUri.toString(), payload)
  } catch(error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return sendReimbursement(payload, attemptNumber + 1)
    }

    throw error
  }
}

async function sendReimbursementV2(payload, attemptNumber = 1) {
  const sendReimbursementUri = new URI(config.apiBaseUrl)
  sendReimbursementUri.segment('arc')
  sendReimbursementUri.segment('v2')
  sendReimbursementUri.segment('reimbursements')

  try {
    return await Vue.prototype.$http.post(sendReimbursementUri.toString(), payload)
  } catch(error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return sendReimbursementV2(payload, attemptNumber + 1)
    }

    throw error
  }
}

async function sendAuthorization(payload, attemptNumber = 1) {
  const sendAuthorizationUri = new URI(config.apiBaseUrl)
  sendAuthorizationUri.segment('arc')
  sendAuthorizationUri.segment('v2')
  sendAuthorizationUri.segment('authorizations')

  try {
    return await Vue.prototype.$http.post(sendAuthorizationUri.toString(), payload)
  } catch(error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return sendAuthorization(payload, attemptNumber + 1)
    }

    throw error
  }
}

async function completeReimbursement(reimbursementId = '', attemptNumber = 1) {
  const completeReimbursementUri = new URI(config.apiBaseUrl)
  completeReimbursementUri.segment('arc')
  completeReimbursementUri.segment('v1')
  completeReimbursementUri.segment('reimbursements')
  completeReimbursementUri.segment(reimbursementId)
  completeReimbursementUri.segment('complete')

  try {
    return await Vue.prototype.$http.post(completeReimbursementUri.toString())
  } catch(error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return completeReimbursement(reimbursementId, attemptNumber + 1)
    }

    throw error
  }
}

async function completeAuthorization(authorizationId = '', attemptNumber = 1) {
  const completeAuthorizationUri = new URI(config.apiBaseUrl)
  completeAuthorizationUri.segment('arc')
  completeAuthorizationUri.segment('v2')
  completeAuthorizationUri.segment('authorizations')
  completeAuthorizationUri.segment(authorizationId.toString())
  completeAuthorizationUri.segment('complete')

  try {
    return await Vue.prototype.$http.post(completeAuthorizationUri.toString())
  } catch(error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return completeAuthorization(authorizationId, attemptNumber + 1)
    }

    throw error
  }
}

async function sendFile(fileSlug, file, reimbursementId = '', attemptNumber = 1) {
  const sendFileFormUri = new URI(config.apiBaseUrl)
  sendFileFormUri.segment('arc')
  sendFileFormUri.segment('v1')
  sendFileFormUri.segment('reimbursements')
  sendFileFormUri.segment(reimbursementId)
  sendFileFormUri.segment('inputs')
  sendFileFormUri.segment(fileSlug)

  const formData = new FormData();
  formData.append('file', file);
 
  try {
    return await Vue.prototype.$http
      .post(sendFileFormUri.toString(), formData, {})
  } catch(error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return sendFile(fileSlug, file, reimbursementId, attemptNumber + 1)
    }

    throw error
  }
}

async function sendFileV2(formSlug, fileSlug, file, attemptNumber = 1) {
  const uri = new URI(config.apiBaseUrl)
  uri.segment('arc')
  uri.segment('v2')
  uri.segment('reimbursements')
  uri.segment('forms')
  uri.segment(formSlug)
  uri.segment('inputs')
  uri.segment(fileSlug)

  const formData = new FormData()
  formData.append('file', file);

  try {
    return await Vue.prototype.$http.post(uri.toString(), formData, {})
  } catch(error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      sendFileV2(formSlug, fileSlug, file, attemptNumber + 1)
    }

    throw error
  }
}

async function sendAuthorizationFile(fileSlug, file, authorizationId = '', attemptNumber = 1) {
  const sendFileFormUri = new URI(config.apiBaseUrl)
  sendFileFormUri.segment('arc')
  sendFileFormUri.segment('v2')
  sendFileFormUri.segment('authorizations')
  sendFileFormUri.segment(authorizationId.toString())
  sendFileFormUri.segment('inputs')
  sendFileFormUri.segment(fileSlug)

  const formData = new FormData();
  formData.append('file', file);
 
  try {
    return await Vue.prototype.$http
      .post(sendFileFormUri.toString(), formData, {})
  } catch(error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return sendAuthorizationFile(fileSlug, file, authorizationId, attemptNumber + 1)
    }

    throw error
  }
}

async function getAuthorization(beneficiaryId, authorizationCode, attemptNumber = 1) {
  const getAuthorizationUri = new URI(config.apiBaseUrl)
  getAuthorizationUri.segment('arc')
  getAuthorizationUri.segment('v1')
  getAuthorizationUri.segment('beneficiaries')
  getAuthorizationUri.segment(beneficiaryId)
  getAuthorizationUri.segment('authorizations')
  getAuthorizationUri.segment(authorizationCode)
  getAuthorizationUri.segment('details')
  
  try {
    return await Vue.prototype.$http.get(getAuthorizationUri.toString())
  } catch(error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return getAuthorization(beneficiaryId, authorizationCode, attemptNumber + 1)
    }

    throw error
  }
}

async function getAuthorizationFileNotReimbursement(fileId, attemptNumber = 1){
  const getAuthorizationFileUri = new URI(config.apiBaseUrl)
  getAuthorizationFileUri.segment('arc')
  getAuthorizationFileUri.segment('v2')
  getAuthorizationFileUri.segment('authorizations')
  getAuthorizationFileUri.segment('files')
  getAuthorizationFileUri.segment(fileId)

  try {
    return await Vue.prototype.$http.get(getAuthorizationFileUri.toString(), {responseType: 'arraybuffer'})
  } catch (error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return getAuthorizationFileNotReimbursement(fileId, attemptNumber + 1)
    }

    throw error
  }
}

async function getAuthorizationFile(fileId, attemptNumber = 1) {
  const getAuthorizationFileUri = new URI(config.apiBaseUrl)
  getAuthorizationFileUri.segment('arc')
  getAuthorizationFileUri.segment('v1')
  getAuthorizationFileUri.segment('reimbursements')
  getAuthorizationFileUri.segment('files')
  getAuthorizationFileUri.segment(fileId)

  try {
    return await Vue.prototype.$http.get(getAuthorizationFileUri.toString(), {responseType: 'arraybuffer'})
  } catch (error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return getAuthorizationFile(fileId, attemptNumber + 1)
    }

    throw error
  }
}

async function sendComment(authorizationCode, payload, attemptNumber = 1) {
  const sendCommentUri = new URI(config.apiBaseUrl)
  sendCommentUri.segment('arc')
  sendCommentUri.segment('v1')
  sendCommentUri.segment('authorizations')
  sendCommentUri.segment(authorizationCode)
  sendCommentUri.segment('comment')

  try {
    return await Vue.prototype.$http.post(sendCommentUri.toString(), payload)
  } catch (error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return sendComment(authorizationCode, payload, attemptNumber + 1)
    }

    throw error
  }
}

function getStatus(authorization) {
  const statusByCoverage = status.find(configuration => configuration.coverageType === authorization.coverageType)

  const authorizationStatus = authorization.status
  const authorizationFormSlug = authorization.authorizationFormSlug || undefined

  if (statusByCoverage) {
    const configurationState = statusByCoverage.states.find(state => {
      const configuredStatus = state.value
      const configuredAuthorizationForm = state.authorizationFormSlug || undefined

      if(authorizationFormSlug){
        return configuredStatus === authorizationStatus && configuredAuthorizationForm === authorizationFormSlug
      }

      return configuredStatus === authorizationStatus
    });

    if(configurationState){
      return configurationState && configurationState.text || '';
    }
  }

  return getStatus({ coverageType: 'DEFAULT', status: authorization.status })
}

function getType(authorizationType) {
   const configurationType = types.find(type => type.slug === authorizationType);
   return configurationType && configurationType.text || '';
}

function getTypeObject(authorizationType) {
  const configurationType = types.find(type => type.slug === authorizationType);
  return configurationType;
}

function getColor(authorization) {
  const statusByCoverage = status.find(configuration => configuration.coverageType === authorization.coverageType)
  if (statusByCoverage) {
    const configurationState = statusByCoverage.states.find(state => state.value === authorization.status);
    return configurationState || {};
  }
  return getColor({ coverageType: 'DEFAULT', status: authorization.status })
}

function getIcon(productStatus) {
  const configurationState = icons.find(icon => icon.productStatus === productStatus);
  return configurationState ? configurationState : {};
}

async function setAuthorizationToErrorStatus(authorizationId = '', attemptNumber = 1){
  const errorAuthorizationURI = new URI(config.apiBaseUrl)
  errorAuthorizationURI.segment('arc')
  errorAuthorizationURI.segment('v2')
  errorAuthorizationURI.segment('authorizations')
  errorAuthorizationURI.segment(authorizationId.toString())
  errorAuthorizationURI.segment('error')

  try {
    return await Vue.prototype.$http.post(errorAuthorizationURI.toString())
  } catch(error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return setAuthorizationToErrorStatus(authorizationId, attemptNumber + 1)
    }

    throw error
  }
}

async function setReimbursementToErrorStatus(reimbursementId = '', attemptNumber = 1){
  const errorReimbursementUri = new URI(config.apiBaseUrl)
  errorReimbursementUri.segment('arc')
  errorReimbursementUri.segment('v1')
  errorReimbursementUri.segment('reimbursements')
  errorReimbursementUri.segment(reimbursementId)
  errorReimbursementUri.segment('error')
  
  try{
    return await Vue.prototype.$http.post(errorReimbursementUri.toString())
  }catch(error){
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return setReimbursementToErrorStatus(reimbursementId, attemptNumber + 1)
    }

    throw error
  }

}

export default {
  getAuthorizationList,
  getAuthorizationForm,
  getSponsorConfiguration,
  getStatus,
  getType,
  getColor,
  getIcon,
  sendFile,
  sendAuthorizationFile,
  sendReimbursement,
  sendAuthorization,
  getTypeObject,
  completeReimbursement,
  completeAuthorization,
  getAuthorization,
  getAuthorizationFile,
  sendComment,
  setAuthorizationToErrorStatus,
  setReimbursementToErrorStatus,
  sendFileV2,
  sendReimbursementV2,
  status,
  types,
  orderBy,
  statusFiltersSecondAssessment,
  getAuthorizationFileNotReimbursement,
  statusFiltersHospitalization
}