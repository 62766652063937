import Vue from 'vue';
import { getDateValues } from '@/utils'

Vue.directive('osg-date', {
  bind(el, binding, vnode) {

    const inputHandler = (event) => {
      let input = event.target.value;
      const isDeleteInput = event.inputType.includes('delete');
      let position = event.target.selectionStart;

      if (isNaN(event.data) && !isDeleteInput) {
        position = position - 1
      }

      const dateValues = getDateValues(input);
      
      const output = dateValues.map((value, index) => {
        return value.length == 2  && index < 2 ? value + '/' : value;
      });

      event.target.value = output.join('').substr(0, 10);
      vnode.componentInstance.onInput(event)

      const cursorOnSeparator = event.target.value.substr(position, 1) === '/';
      
      event.target.selectionEnd = cursorOnSeparator && !isDeleteInput ? position + 1 : position;
    }

    el.addEventListener('input', inputHandler);

  }
})