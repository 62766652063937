<template>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">
        <path class="cls-1" d="M61.28,28.49,58.07,12.41a4.85,4.85,0,0,0-4-1.9H35V50.28H46.08a4.84,4.84,0,0,0,4-1.9L61.28,32.3A3.23,3.23,0,0,0,61.28,28.49Z"/>
        <path class="cls-2" d="M35,10.51H15.67a4.84,4.84,0,0,0-4,1.9L8.47,28.49a3.23,3.23,0,0,0,0,3.81l11.2,16.08a4.86,4.86,0,0,0,4,1.9H35Z"/>
        <rect class="cls-2" x="3" y="18.75" width="63.75" height="68.85" rx="6"/>
        <path class="cls-3" d="M9.1,18.75H35a0,0,0,0,1,0,0V87.61a0,0,0,0,1,0,0H9.1a6,6,0,0,1-6-6V24.75A6,6,0,0,1,9.1,18.75Z"/>
        <path class="cls-3" d="M66.6,87.83l-17.36-.12a5.18,5.18,0,0,1-4.45-2.61L41,78.49a5.17,5.17,0,0,1,0-5.16l3.85-6.56a5.16,5.16,0,0,1,4.48-2.55l17.36.13,17.36.12a5.13,5.13,0,0,1,4.45,2.61l3.76,6.61a5.17,5.17,0,0,1,0,5.16L88.44,85.4A5.16,5.16,0,0,1,84,88Z"/>
        <path class="cls-2" d="M66.6,87.83,84,88a5.16,5.16,0,0,0,4.48-2.55l3.85-6.55a5.17,5.17,0,0,0,0-5.16l-3.76-6.61a5.13,5.13,0,0,0-4.45-2.61l-17.36-.12Z"/>
    </svg>
</template>

<script>
export default {
    name: 'DrugIcon',
    props: {
        color: {
            type: String,
            default: 'primary'
        }
    },
    computed: {
        theme() {
            return this.$vuetify.theme.themes.light;
        }
    }
}
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: #bbdaff;
}

.cls-2 {
  fill: #8bbbff;
}

.cls-3 {
  fill: #3e8dff;
}
</style>