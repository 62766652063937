import Vue from 'vue'
import { decamelizeKeys, camelizeKeys } from 'humps'
import { config } from '@/plugins/env.config'

const searchAgreementDocuments = async (countryCode, personaAttributes, owner, documentType, attemptNumber = 1) => {
  const body = decamelizeKeys({
    applicationSlug: config.applicationSlug,
    countryCode,
    documentType,
    personaAttributes,
    owner,
    personaType: 'beneficiary'
  })

  try {
    const agreementDocuments = await Vue.prototype.$http.post(`${config.apiBaseUrl}/legal-agreements/v1/documents/search-acceptance`, body)
    return camelizeKeys(agreementDocuments.data);
  } catch (error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return searchAgreementDocuments(countryCode, personaAttributes, documentType, owner, attemptNumber + 1)
    }
    throw error
  }
}

const acceptDocument = async(agreementDocument, personaAttributes, attemptNumber = 1) => {
  const body = decamelizeKeys({
    personaAttributes,
    personaType: 'beneficiary'
  })

  try{
    return Vue.prototype.$http.post(`${config.apiBaseUrl}/legal-agreements/v1/documents/${agreementDocument.documentSlug}/accept`, body)
  } catch (error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return acceptDocument(agreementDocument, personaAttributes, attemptNumber + 1)
    }
    throw error
  }
}

const downloadDocument = async (agreementDocument, attemptNumber = 1) => {
  const headers = {
    headers: {
      'Accept': 'text/html'
    }
  }

  try {
    return Vue.prototype.$http.get(`${config.apiBaseUrl}/legal-agreements/v1/documents/${agreementDocument.documentSlug}/files`, headers)
  } catch (error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return downloadDocument(agreementDocument, attemptNumber + 1)
    }
    throw error
  }
  
}

export default {
  searchAgreementDocuments,
  acceptDocument,
  downloadDocument
}