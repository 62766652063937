import {
  SET_TOKEN,
  SET_STATUS_AUTH_REQUEST,
  SET_STATUS_AUTH_SUCCESS,
  SET_STATUS_AUTH_ERROR,
  SET_STATUS_AUTH_LOGOUT,
  CLIENT_TOKEN_SUCCESS,
  CLIENT_TOKEN_ERROR,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  RESET_CHANGE_PASSWORD_STATUS,
  SET_EMAIL
} from '../mutation-types';

export default {
  [SET_TOKEN](state, parameters) {
    const { accessToken } = parameters
    Object.assign(state, { ...state, accessToken })
  },
  [SET_STATUS_AUTH_REQUEST](state) {
    state.authStatus = 'loading';
  },
  [SET_STATUS_AUTH_SUCCESS](state, parameters) {
    state.authStatus = 'success'
    state.accessToken = parameters.accessToken
    state.refreshToken = parameters.refreshToken
    state.tokenType = parameters.tokenType
    state.tokenExtensions = parameters.extensions
  },
  [SET_STATUS_AUTH_ERROR] (state) {
    state.authStatus = 'error'
  },
  [SET_STATUS_AUTH_LOGOUT] (state) {
    state.accessToken = ''
    state.refreshToken = ''
    state.tokenExtensions = ''
    state.termsAndConditionsAcceptanceRequired = false
    state.doctorInformation = ''
    localStorage.clear()
  },
  [CLIENT_TOKEN_SUCCESS] (state, parameters) {
    state.clientToken = parameters.accessToken
  },
  [CLIENT_TOKEN_ERROR] (state) {
    state.clientToken = ''
  },
  [FORGOT_PASSWORD_SUCCESS] (state) {
    localStorage.removeItem('clientToken')
    state.clientToken = ''
    state.forgotPasswordStatus = 'success'
  },
  [FORGOT_PASSWORD_ERROR] (state) {
    localStorage.removeItem('clientToken')
    state.clientToken = ''
    state.forgotPasswordStatus = 'error'
  },
  [CHANGE_PASSWORD_REQUEST] (state) {
    state.changePasswordStatus = 'loading'
  },
  [CHANGE_PASSWORD_SUCCESS] (state) {
    state.changePasswordStatus = 'success'
  },
  [CHANGE_PASSWORD_ERROR] (state) {
    state.changePasswordStatus = 'error'
  },
  [RESET_CHANGE_PASSWORD_STATUS] (state) {
    state.changePasswordStatus = ''
  },
  [SET_EMAIL] (state, payload) {
    state.email = payload
  }
}

