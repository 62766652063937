import authService from '@/services/auth.service'
import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import vuei18n from '@/plugins/i18n'
import { SET_TOKEN } from '@/store/mutation-types';
import { safeNavigation } from '@/utils/safe-navigation.utils'

export default (vuexStore) => {
  const axiosInstance = axios.create()

  axiosInstance.interceptors.request.use((config) => {
    const accessToken = vuexStore.getters['auth/accessToken'];
    config.headers.Authorization = `Bearer ${accessToken}`
    config.headers['accept-language'] = vuei18n.locale

    return config
  }, (error) => Promise.reject(error))

  axiosInstance.interceptors.response.use((response) => response, 
    async (error) => {
      const originalRequest = error.config
      const response = error.response
      const refreshToken = vuexStore.getters['auth/refreshToken'];

      if (response.status !== 401) {
        return Promise.reject(error)
      }

      if (originalRequest._retry) {
        router.push('/login').catch(safeNavigation)
      }

      originalRequest._retry = true

      try {
        const tokenData = await authService.refreshToken(refreshToken)
        const accessToken = tokenData.accessToken
        const tokenType = tokenData.tokenType
        vuexStore.commit(`auth/${SET_TOKEN}`, { tokenType, accessToken });

        return axiosInstance(originalRequest)
      } catch (e) {
        router.push('/login').catch(safeNavigation)
      }
    }
  )

  Vue.prototype.$http = axiosInstance
}