<template>
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">
    <path
      class="cls-1"
      d="M48,13.65V8.08H34.34c-.83,0-1.5.45-1.5,1V10a1.85,1.85,0,0,0,.48,1.22l4.46,5.2V41.61a6.63,6.63,0,0,1-1.35,4L10.3,79.91A4.82,4.82,0,0,0,9.06,83v2.28c0,1.44,1.73,2.6,3.86,2.6H48V13.65Z"
    />
    <path
      class="cls-2"
      d="M85.7,79.91,59.57,45.61a6.63,6.63,0,0,1-1.35-4V16.4l4.46-5.2A1.85,1.85,0,0,0,63.16,10V9.09c0-.56-.67-1-1.5-1H48v5.57h0V47.36h0V87.92H83.08c2.13,0,3.86-1.16,3.86-2.6V83A4.82,4.82,0,0,0,85.7,79.91Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'LaboratoryIcon',
  props: {
    color: {
      type: String,
      default: 'primary'
    }
  },
  computed: {
    theme() {
      return this.$vuetify.theme.themes.light;
    }
  }
};
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: #3e8dff;
}

.cls-2 {
  fill: #8bbbff;
}
</style>