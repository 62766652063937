export default {
  loader: false,
  alert: {
    active: false,
    type: 'error',
    message: null,
    timeout: 4000
  },
  snackbar: {
    active: false,
    message: null,
    timeout: 5000
  },
  filters: [],
}