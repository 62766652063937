<template>
<svg width="229" height="166" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="m112.77 144.409 2.311-2.302L49.409 76.46l-2.3 2.301a5.69 5.69 0 0 0 0 8.046l57.579 57.602a5.67 5.67 0 0 0 1.852 1.249 5.67 5.67 0 0 0 6.23-1.249z" fill="#DCDDDE"/>
    <path d="m115.036 142.107 2.299-2.302a5.685 5.685 0 0 0 1.665-4.023 5.691 5.691 0 0 0-1.665-4.022L59.757 74.147a5.691 5.691 0 0 0-8.05 0l-2.299 2.301 65.628 65.659z" fill="#BCBEC0"/>
    <path d="M60.11 127.742c22.434 0 40.62-18.193 40.62-40.636 0-22.444-18.186-40.637-40.62-40.637-22.433 0-40.619 18.193-40.619 40.637 0 22.443 18.186 40.636 40.62 40.636z" fill="#BCBEC0"/>
    <path d="M31.388 58.377a40.604 40.604 0 0 1 28.718-11.93 40.604 40.604 0 0 1 28.733 11.891 40.683 40.683 0 0 1 11.918 28.746 40.68 40.68 0 0 1-11.879 28.762l-57.49-57.47z" fill="#DCDDDE"/>
    <path d="M60.11 118.945c17.488 0 31.664-14.255 31.664-31.84 0-17.583-14.176-31.838-31.664-31.838-17.487 0-31.664 14.255-31.664 31.839 0 17.584 14.177 31.839 31.664 31.839z" fill="#EAEAEA"/>
    <path d="M181.559 44.953h-58.475a4.424 4.424 0 0 0-4.422 4.426v6.74a4.424 4.424 0 0 0 4.422 4.427h58.475a4.424 4.424 0 0 0 4.422-4.427v-6.74a4.424 4.424 0 0 0-4.422-4.426z" fill="#DCDDDE"/>
    <path d="M151.034 44.953h30.525a4.423 4.423 0 0 1 4.423 4.426v6.74a4.423 4.423 0 0 1-4.423 4.427h-30.525V44.953z" fill="#BCBEC0"/>
    <path d="M181.559 64.807h-58.475a4.424 4.424 0 0 0-4.422 4.426v6.74a4.424 4.424 0 0 0 4.422 4.427h58.475a4.424 4.424 0 0 0 4.422-4.427v-6.74a4.424 4.424 0 0 0-4.422-4.426z" fill="#DCDDDE"/>
    <path d="M151.034 64.807h30.525a4.423 4.423 0 0 1 4.423 4.426v6.74a4.423 4.423 0 0 1-4.423 4.427h-30.525V64.807z" fill="#BCBEC0"/>
    <path d="M181.559 84.66h-58.475a4.424 4.424 0 0 0-4.422 4.427v6.74a4.424 4.424 0 0 0 4.422 4.426h58.475a4.424 4.424 0 0 0 4.422-4.427v-6.74a4.424 4.424 0 0 0-4.422-4.426z" fill="#DCDDDE"/>
    <path d="M151.034 84.66h30.525a4.423 4.423 0 0 1 4.423 4.427v6.74a4.423 4.423 0 0 1-4.423 4.427h-30.525V84.659z" fill="#BCBEC0"/>
    <path d="M181.559 104.514h-58.475a4.424 4.424 0 0 0-4.422 4.426v6.74a4.425 4.425 0 0 0 4.422 4.427h58.475a4.425 4.425 0 0 0 4.422-4.427v-6.74a4.424 4.424 0 0 0-4.422-4.426z" fill="#DCDDDE"/>
    <path d="M151.034 104.514h30.525a4.423 4.423 0 0 1 4.423 4.426v6.74a4.424 4.424 0 0 1-4.423 4.427h-30.525v-15.593z" fill="#BCBEC0"/>
    <path d="M155.268 59.262c.325.866.875 1.63 1.592 2.213l20.597 16.71 1.106.864a5.914 5.914 0 0 0 3.715 1.317V19.92h-22.112a5.162 5.162 0 0 0-5.152 5.168v32.459a5.022 5.022 0 0 0 0 .575c.037.3.096.595.177.885l.077.255z" fill="#FF726D"/>
    <path d="M209.188 59.262a5.216 5.216 0 0 1-1.592 2.213l-20.587 16.71-1.105.864a5.857 5.857 0 0 1-3.704 1.317V19.92h22.112a5.162 5.162 0 0 1 5.152 5.168v32.459c.011.191.011.383 0 .575a6.06 6.06 0 0 1-.177.885l-.099.255z" fill="#F99"/>
    <path d="m195.298 54.761-20.975-20.995-5.152 5.157 20.975 20.995 5.152-5.157z" fill="#FFDEDE"/>
    <path d="m174.392 59.875 20.842-20.862-5.183-5.188-20.842 20.862 5.183 5.188z" fill="#FFDEDE"/>
</svg>
</template>

<script>
export default {
    name: 'EmptyStateIcon'
};
</script>

