import {
  SHOW_LOADER,
  CONFIGURE_ALERT,
  CONFIGURE_SNACKBAR,
  SET_FILTERS
}from './mutation-types'

export default {
  [SHOW_LOADER]: (state, payload) => {
    state.loader = payload
  },
  [CONFIGURE_ALERT]: (state, payload) => {
    state.alert.active = payload.active
    state.alert.type = payload.type
    state.alert.message = payload.message
    state.alert.timeout = payload.timeout || state.alert.timeout
  },
  [CONFIGURE_SNACKBAR]: (state, payload) => {
    state.snackbar = payload
  },
  [SET_FILTERS] (state, payload) {
    const { slug, value, route } = payload;
    if(slug){
        const filtersApplied = [...state.filters];
        const index = filtersApplied.findIndex(filter => filter.slug === slug && filter.route === route);
        if (index >= 0) {
            filtersApplied[index].value = value;
        } else {
            filtersApplied.push({ slug, value, ...(route && { route }) });
        }
        state.filters = filtersApplied
    }else{
        state.filters = payload
    }
  },
}