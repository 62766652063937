import URI from 'urijs'
import axios from 'axios'
import { camelizeKeys } from 'humps'
import vuei18n from '@/plugins/i18n'
import { config } from '@/plugins/env.config'

axios.defaults.headers.common['accept-language'] = vuei18n.locale;

async function getClientToken(attemptNumber = 1) {
  const getTokenUri = new URI(config.apiBaseUrl)
  getTokenUri.segment('v1')
  getTokenUri.segment('oauth')
  getTokenUri.segment('token')
  getTokenUri.addSearch('grant_type', 'client_credentials')

  const getTokenHeaders = {
    Authorization: `Basic ${Buffer.from(`${config.clientId}:${config.clientSecret}`).toString('base64')}`
  }

  let tokenResponse
  try {
    tokenResponse = await axios.post(getTokenUri.toString(), {}, {
      headers: getTokenHeaders
    })
  } catch (error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return getInternalToken(attemptNumber + 1)
    }

    throw error
  }

  const token = camelizeKeys(tokenResponse.data)
  if (!token || !token.accessToken || !token.tokenType) {
    throw new Error('Invalid server response')
  }

  return {
    accessToken: token.accessToken,
    tokenType: token.tokenType
  }
}

async function getInternalToken(user, attemptNumber = 1) {
  const getTokenUri = new URI(config.apiBaseUrl)
  getTokenUri.segment('v1')
  getTokenUri.segment('oauth')
  getTokenUri.segment('token')
  getTokenUri.addSearch('username', user.username.toLowerCase())
  getTokenUri.addSearch('password', user.password)
  getTokenUri.addSearch('grant_type', 'password')

  const getTokenHeaders = {
    Authorization: `Basic ${Buffer.from(`${config.clientId}:${config.clientSecret}`).toString('base64')}`
  }

  let tokenResponse
  try {
    tokenResponse = await axios.post(getTokenUri.toString(), {}, {
      headers: getTokenHeaders
    })
  } catch (error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return getInternalToken(user.username, user.password, attemptNumber + 1)
    }

    throw error
  }

  const token = camelizeKeys(tokenResponse.data)
  if (!token || !token.accessToken || !token.tokenType) {
    throw new Error('Invalid server response')
  }

  return {
    accessToken: token.accessToken,
    tokenType: token.tokenType,
    refreshToken: token.refreshToken,
    extensions: token.extensions,
    termsAndConditionsAcceptanceRequired: token.termsAndConditionsAcceptanceRequired
  }
}

async function requestPasswordChange(email, attemptNumber = 1) {
  const getTokenUri = new URI(config.apiBaseUrl)
  getTokenUri.segment('v1')
  getTokenUri.segment('users')
  getTokenUri.segment('request-password-change')

  const getTokenHeaders = {
    Authorization: `Bearer ${localStorage.getItem('clientToken')}`
  }

  try {
    email.email = email.email.toLowerCase();
    return await axios.post(getTokenUri.toString(), email, {
      headers: getTokenHeaders
    })
  } catch (error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return requestPasswordChange(email, attemptNumber + 1)
    }

    throw error
  }
}

async function changePassword(password, token, attemptNumber = 1) {
  const getTokenUri = new URI(config.apiBaseUrl)
  getTokenUri.segment('v1')
  getTokenUri.segment('users')
  getTokenUri.segment('change-password')

  const getTokenHeaders = {
    Authorization: `Bearer ${token}`
  }

  try {
    return await axios.post(getTokenUri.toString(), { password: password }, {
      headers: getTokenHeaders
    })
  } catch (error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return changePassword(password, attemptNumber + 1)
    }

    throw error
  }
}

async function validateToken(token, attemptNumber = 1) {
  const getTokenUri = new URI(config.apiBaseUrl)
  getTokenUri.segment('v1')
  getTokenUri.segment('oauth')
  getTokenUri.segment('check_token')
  getTokenUri.addQuery({token})

  const getTokenHeaders = {
    Authorization: `Basic ${Buffer.from(`${config.clientId}:${config.clientSecret}`).toString('base64')}`
  }

  try {
    return await axios.get(getTokenUri.toString(), {
      headers: getTokenHeaders
    })
  } catch (error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return validateToken(token, attemptNumber + 1)
    }

    throw error
  }
}

async function refreshToken(refreshToken, attemptNumber = 1) {
  const getTokenUri = new URI(config.apiBaseUrl)
  getTokenUri.segment('v1')
  getTokenUri.segment('oauth')
  getTokenUri.segment('token')
  getTokenUri.addSearch('grant_type', 'refresh_token')
  getTokenUri.addSearch('refresh_token', refreshToken)

  const getTokenHeaders = {
    Authorization: `Basic ${Buffer.from(`${config.clientId}:${config.clientSecret}`).toString('base64')}`
  }

  let tokenResponse
  try {
    tokenResponse = await axios.post(getTokenUri.toString(), {}, {
      headers: getTokenHeaders
    })
  } catch (error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return refreshToken(refreshToken, attemptNumber + 1)
    }

    throw error
  }

  const token = camelizeKeys(tokenResponse.data)
  if (!token || !token.accessToken || !token.tokenType) {
    throw new Error('Invalid server response')
  }

  return {
    accessToken: token.accessToken,
    tokenType: token.tokenType,
    refreshToken: token.refreshToken,
    extensions: token.extensions,
    termsAndConditionsAcceptanceRequired: token.termsAndConditionsAcceptanceRequired,
    tfaRequired: token.tfaRequired,
    tfaAvailableMethods: token.tfaAvailableMethods
  }
}

export default {
  getClientToken,
  getInternalToken,
  requestPasswordChange,
  changePassword,
  validateToken,
  refreshToken
}