<template>
  <svg width="38" height="40" viewBox="0 0 38 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M31.7002 5.00412H8.19965C7.5495 5.00412 7.02246 5.56376 7.02246 6.25412V35.9125C7.02246 36.6028 7.5495 37.1625 8.19965 37.1625H31.7002C32.3503 37.1625 32.8773 36.6028 32.8773 35.9125V6.25412C32.8773 5.56376 32.3503 5.00412 31.7002 5.00412Z" fill="#3E8DFF"/>
<path d="M29.664 2.83739H6.16351C5.51337 2.83739 4.98633 3.39703 4.98633 4.08739V33.7457C4.98633 34.4361 5.51337 34.9957 6.16351 34.9957H29.664C30.3142 34.9957 30.8412 34.4361 30.8412 33.7457V4.08739C30.8412 3.39703 30.3142 2.83739 29.664 2.83739Z" fill="#BBDAFF"/>
<path d="M17.9111 2.83739H29.6634C29.9756 2.83739 30.275 2.96908 30.4958 3.2035C30.7165 3.43792 30.8405 3.75587 30.8405 4.08739V33.7499C30.8405 34.0814 30.7165 34.3994 30.4958 34.6338C30.275 34.8682 29.9756 34.9999 29.6634 34.9999H17.9111V2.83739Z" fill="#8BBBFF"/>
<path d="M17.1858 25.654H17.1309C16.9326 25.6412 16.7441 25.5577 16.596 25.4171C16.4479 25.2764 16.3488 25.0868 16.3147 24.879L14.9806 16.7207L13.7642 20.2165C13.7023 20.397 13.5897 20.5529 13.4416 20.6632C13.2935 20.7735 13.1171 20.8328 12.9362 20.8332H7.16016V18.9582H12.3201L14.4626 12.804C14.5298 12.6101 14.6554 12.4451 14.8199 12.3348C14.9845 12.2245 15.1787 12.175 15.3725 12.194C15.5662 12.213 15.7486 12.2995 15.8914 12.4399C16.0341 12.5804 16.1291 12.7669 16.1617 12.9707L17.4174 20.6707L18.5945 16.504C18.6502 16.3125 18.7621 16.145 18.9139 16.026C19.0658 15.9071 19.2495 15.8429 19.4382 15.8429C19.6269 15.8429 19.8106 15.9071 19.9624 16.026C20.1143 16.145 20.2262 16.3125 20.2818 16.504L20.843 18.379L22.389 12.8582C22.4436 12.6617 22.5571 12.4895 22.7121 12.3677C22.8672 12.2459 23.0555 12.1811 23.2483 12.1832H23.2719C23.4675 12.1902 23.6553 12.2661 23.8059 12.3988C23.9565 12.5315 24.0613 12.7136 24.1038 12.9165L25.4811 18.9665H28.6516V20.8332H24.7865C24.5888 20.8333 24.3968 20.7626 24.2416 20.6326C24.0864 20.5025 23.977 20.3207 23.9311 20.1165L23.1463 16.729L21.7219 21.8332C21.6686 22.0233 21.5595 22.1904 21.4107 22.3103C21.2618 22.4302 21.0808 22.4966 20.894 22.4998V22.4998C20.7061 22.5003 20.523 22.4371 20.3713 22.3194C20.2195 22.2018 20.1071 22.0358 20.0503 21.8457L19.4814 19.9415L18.0295 24.9998C17.9722 25.1896 17.8596 25.3553 17.708 25.4728C17.5564 25.5904 17.3736 25.6538 17.1858 25.654V25.654Z" fill="#3E8DFF"/>
</svg>

</template>

<script>
export default {
  name: 'DiagnosticTestsIcon',
  props: {
    color: {
      type: String,
      default: 'primary'
    }
  },
  computed: {
    theme() {
      return this.$vuetify.theme.themes.light;
    }
  }
};
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: #8bbbff;
}

.cls-2 {
  fill: #3e8dff;
}

.cls-3 {
  fill: #bbdaff;
}
</style>