<template>
<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="none" viewBox="0 0 38 38">
    <circle cx="19" cy="19" r="18" stroke="#949494" stroke-dasharray="1 5" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <path fill="#949494" d="M24.334 12.334H13.667c-.733 0-1.326.6-1.326 1.333l-.007 12L15.001 23h9.333c.733 0 1.333-.6 1.333-1.333v-8c0-.733-.6-1.333-1.333-1.333zM15.001 17h8v1.334h-8V17zm5.333 3.334h-5.333V19h5.333v1.334zm2.667-4h-8V15h8v1.334z"/>
</svg>
</template>
<script>
export default {
  name: 'MessageCircleIcon'
}
</script>