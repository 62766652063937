<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M2.34448 2.34378C5.46929 -0.781027 10.5269 -0.781496 13.6522 2.34378C16.165 4.85656 16.6398 8.59287 15.1542 11.5684L15.9778 15.1911C16.0849 15.662 15.6629 16.0842 15.1918 15.9771L11.5691 15.1535C6.30162 17.7834 0.00249004 13.976 0.00249004 7.99766C0.00249004 5.86196 0.834225 3.85404 2.34448 2.34378ZM6.33039 9.98876H11.3342C11.697 9.98876 11.991 9.69472 11.991 9.332C11.991 8.96929 11.697 8.67525 11.3342 8.67525H6.33039C5.96768 8.67525 5.67364 8.96929 5.67364 9.332C5.67364 9.69472 5.96768 9.98876 6.33039 9.98876ZM4.66242 7.32005H11.3342C11.697 7.32005 11.991 7.02601 11.991 6.66329C11.991 6.30058 11.697 6.00654 11.3342 6.00654H4.66242C4.2997 6.00654 4.00567 6.30058 4.00567 6.66329C4.00567 7.02601 4.29974 7.32005 4.66242 7.32005Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="16" height="16" fill="white" transform="matrix(-1 0 0 1 16 0)"/>
</clipPath>
</defs>
</svg>
</template>

<script>
export default {
    name: 'ChatIcon'
};
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: #ff726d;
}
</style>
