export default {
    accessToken: localStorage.getItem('accessToken') || '',
    refreshToken: localStorage.getItem('refreshToken') || '',
    clientToken: localStorage.getItem('clientToken') || '',
    tokenExtensions: JSON.parse(localStorage.getItem('tokenExtensions')) || '',
    authStatus: '',
    tokenType: '',
    forgotPasswordStatus: '',
    changePasswordStatus: '',
    acceptTermsAndConditionsStatus: '',
    termsAndConditionsAcceptanceRequired: localStorage.getItem('termsAndConditionsAcceptanceRequired') || false,
    password: null,
    email: null
}
  