function getSizeDescription(size) {
  let value = (size/1024)
  let sizeDescription = 'kb'
  if (value >= 1024) {
    value = (value/1024)
    sizeDescription = 'Mb'
  }
  return (value).toFixed(1).concat(' ', sizeDescription)
}

function getExtensions(mimeTypes) {
  const isNotExtension = mimeTypes.includes('/')
  let extensions = []
  if (isNotExtension) {
    const types = mimeTypes.split(',')
    extensions = types.map(type => type.split('/')[1].toUpperCase())
  }
  return isNotExtension ? extensions.join(', ') : 
  mimeTypes.split(',').map(type => type.toUpperCase()).join(', ')
}

function validateFileType(file, field) {
  if (!file) {
    return true;
  }

  if (field.mimeTypes.includes(file.type)) {
    return true;
  }

  for (let type of field.mimeTypes.split(',')) {
    if (file.name.endsWith(type)) {
      return true;
    }
  }
  
  return false;
}

export { getSizeDescription, getExtensions, validateFileType }