import {
    SET_PENDING_AGREEMENT_DOCUMENTS,
    SET_PENDING_HTML_DOCUMENTS,
    SET_ERROR_AGREEMENT
} from '../mutation-types'

export default {
  [SET_PENDING_AGREEMENT_DOCUMENTS] (state, payload) {
    state.pendingAgreementDocuments = payload
  },
  [SET_PENDING_HTML_DOCUMENTS] (state, payload) {
    state.pendingHtmlDocuments = payload
  },
  [SET_ERROR_AGREEMENT] (state, payload) {
    state.error['type'] = payload.type
    state.error['message'] = payload.message
  }
}