<template>
  <svg viewBox="0 0 398.76 109">
    <g id="Layer_2"
      data-name="Layer 2">
      <g id="Layer_1-2"
        data-name="Layer 1">
        <path class="cls-1"
          d="M160.62,54.44c0-13.84,10.88-24,25.65-24s25.64,10.16,25.64,24-10.87,24-25.64,24S160.62,68.29,160.62,54.44Zm38.11,0c0-8-5.47-13.18-12.46-13.18s-12.46,5.2-12.46,13.18,5.47,13.19,12.46,13.19S198.73,62.42,198.73,54.44Z" />
        <path class="cls-1"
          d="M221.32,73.36l4.28-9.62a29.14,29.14,0,0,0,15.23,4.55c5.41,0,7.52-1.52,7.52-3.76,0-7.32-26.24-2-26.24-19.12,0-8.24,6.72-15,20.44-15,6,0,12.19,1.39,16.74,4l-4,9.7a27.17,27.17,0,0,0-12.79-3.56c-5.47,0-7.45,1.84-7.45,4.15,0,7,26.18,1.78,26.18,18.79,0,8-6.73,14.9-20.44,14.9C233.19,78.44,225.67,76.4,221.32,73.36Z" />
        <path class="cls-1"
          d="M272.76,31h13V77h-13Z" />
        <path class="cls-1"
          d="M331.76,53h12V72.12A36.89,36.89,0,0,1,323.6,78c-15.29,0-26.47-10-26.47-24s11.18-24,26.74-24c9,0,16.25,3,21,8.44L336.21,46a15.21,15.21,0,0,0-11.65-5.15c-8.84,0-14.52,6-13.63,15a12.72,12.72,0,0,0,11.77,11.3,18.39,18.39,0,0,0,9.06-1.45Z" />
        <path class="cls-1"
          d="M355.76,56.58V31h14V56.18c0,7.92,3.2,11.08,8.53,11.08s8.47-3.16,8.47-11.08V31h12V56.58c0,13.78-8,21.49-21.5,21.49S355.76,70.36,355.76,56.58Z" />
        <polygon class="cls-1"
          points="75.7 32 57.99 32 71.27 55 57.99 78 75.7 78 88.98 55 75.7 32" />
        <polygon class="cls-1"
          points="58.02 16 66.68 1 93.52 1 124.7 55 93.52 109 75.64 109 106.96 54.75 84.59 16 58.02 16" />
        <polygon class="cls-1"
          points="66.68 93 58.02 108 31.18 108 0 54 31.18 0 49.06 0 17.74 54.25 40.11 93 66.68 93" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'OsiguLogo',
};
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: #3e8dff;
}
</style>