<template>
  <svg viewBox="0 0 16 16">
    <path class="st0" d="M13.5,8.5v5h-2.8V8.8c0-1.2-0.4-2-1.4-2c-0.8,0-1.2,0.5-1.4,1.1C7.8,8.1,7.8,8.3,7.8,8.6v4.9H5c0,0,0-7.9,0-8.8
	h2.8V6c0,0,0,0,0,0h0v0c0.4-0.6,1-1.4,2.5-1.4C12.1,4.5,13.5,5.8,13.5,8.5z M2.1,0.5c-1,0-1.6,0.7-1.6,1.5c0,0.8,0.6,1.5,1.5,1.5h0
	c1,0,1.6-0.7,1.6-1.5C3.6,1.2,3,0.5,2.1,0.5z M0.7,13.5h2.8V4.7H0.7V13.5z"/>
  </svg>
</template>

<script>
export default {
  name: 'LinkedInIcon',
};
</script>

<style lang="scss" scoped>

.st0 {
  fill:#4F4F4F;
}
</style>