import authService from '@/services/auth.service'
import {
  SET_TOKEN,
  SET_STATUS_AUTH_ERROR,
  SET_STATUS_AUTH_LOGOUT,
  SET_STATUS_AUTH_REQUEST,
  SET_STATUS_AUTH_SUCCESS,
  CLIENT_TOKEN_ERROR,
  CLIENT_TOKEN_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  RESET_CHANGE_PASSWORD_STATUS,
  SET_EMAIL
} from '../mutation-types'

const setToken = ({ commit }, payload) => {
  commit(SET_TOKEN, payload);
}

const requestLogin = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    commit(SET_STATUS_AUTH_REQUEST)
    authService.getInternalToken(payload)
    .then(resp => {
      localStorage.setItem('accessToken', resp.accessToken)
      localStorage.setItem('refreshToken', resp.refreshToken)
      localStorage.setItem('tokenExtensions', JSON.stringify(resp.extensions))
      commit(SET_STATUS_AUTH_SUCCESS, resp)
      resolve(resp)
    })
    .catch(error => {
      commit(SET_STATUS_AUTH_ERROR)
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('tokenExtensions')
      reject(error)
    })
  })
}

const logout = ({ commit }) => {
  commit(SET_STATUS_AUTH_LOGOUT)
}

const requestClientToken = ({ commit }) => {
  return new Promise((resolve, reject) => {
    authService.getClientToken()
    .then(res => {
      localStorage.setItem('clientToken', res.accessToken)
      commit(CLIENT_TOKEN_SUCCESS, res)
      resolve(res)
    })
    .catch(error => {
      commit(CLIENT_TOKEN_ERROR)
      localStorage.removeItem('clientToken')
      reject(error)
    })
  })
}

const requestForgotPassword = ({ commit }, email) => {
  return new Promise((resolve, reject) => {
    authService.requestPasswordChange(email)
    .then(res => {
      commit(FORGOT_PASSWORD_SUCCESS)
      resolve(res)
    })
    .catch(error => {
      commit(FORGOT_PASSWORD_ERROR)
      reject(error)
    })
  })
}

const requestChangePassword = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    commit(CHANGE_PASSWORD_REQUEST)
    authService.changePassword(payload.password, payload.token)
      .then(res => {
        commit(CHANGE_PASSWORD_SUCCESS)
        resolve(res)
      })
      .catch(error => {
        commit(CHANGE_PASSWORD_ERROR)
        reject(error)
      })
  })
}

const resetChangePasswordStatus = ({ commit }) => {
  commit(RESET_CHANGE_PASSWORD_STATUS)
}

const setEmail = ({ commit }, payload) => {
  commit(SET_EMAIL, payload)
}

export default {
    setToken,
    requestLogin,
    logout,
    requestClientToken,
    requestForgotPassword,
    requestChangePassword,
    resetChangePasswordStatus,
    setEmail
}