export const statusConfiguration = [
    {
      status: 'TOTAL',
      color: '#f0f2f6'
    },
    {
      status: 'USED',
      color: '#528ef7',
      colorTransparent: '#528ef75c'
    },
    {
      status: 'AVAILABLE',
      color: '#f0f2f6'
    },
]