<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.35 81.26">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M42.17,0a3.18,3.18,0,0,0-2.78,1.62l-38,66.63A10.19,10.19,0,0,0,0,73.33V77a4.2,4.2,0,0,0,4.18,4.22h38"
        />
        <path
          class="cls-2"
          d="M42.17,81.26h38A4.2,4.2,0,0,0,84.35,77V73.33A10.3,10.3,0,0,0,83,68.25L45,1.62A3.18,3.18,0,0,0,42.17,0"
        />
        <polygon
          class="cls-3"
          points="45.59 57.61 38.76 57.61 36.48 30.75 47.87 30.75 45.59 57.61"
        />
        <rect class="cls-3" x="38.68" y="62.01" width="6.98" height="6.98" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
    name: 'ErrorIcon'
};
</script>

<style>
.cls-1 {
  fill: #ff726d;
}
.cls-2 {
  fill: #ffa4a4;
}
.cls-3 {
  fill: #ffdede;
}
</style>