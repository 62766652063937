import types from "./types";

export default {
  [types.AUTHORIZATION_LOAD_SUCCESS](state, payload) {
    state.authorizations = payload.content;
    state.links = payload.links;
    state.page = payload.page;
  },
  [types.AUTHORIZATION_LOAD_ERROR](state) {
    state.errorAlert = true;
  },
  [types.AUTHORIZATION_LOADING](state, payload) {
    state.authorizationLoading = payload;
  },
  [types.AUTHORIZATION_COMMENT_LOADING](state, payload) {
    state.authorizationCommentLoading = payload;
  },
  [types.AUTHORIZATION_FORM_LOAD_SUCCESS](state, payload) {
    state.authorizationForms = payload.response;
    state.authorizationFormsAndSteps = payload.formsByStep;
  },
  [types.AUTHORIZATION_SPONSOR](state, payload) {
    state.sponsorConfigurations = payload;
  },
  [types.SET_CURRENT_FORM](state, payload) {
    state.currentForm = payload;
  },
  [types.SET_CURRENT_STEP](state, payload) {
    state.currentStep = payload;
  },
  [types.SET_CURRENT_COVERAGE](state, payload) {
    state.currentCoverage = payload;
  },
  [types.SET_ERROR](state, payload) {
    state.error = payload;
  },
  [types.ADD_FILES](state, payload) {
    state.files = payload;
  },
  [types.REMOVE_FILE](state, id) {
    state.files = state.files.filter((file) => file.fileId !== id);
  },
  [types.SET_STEP_ERROR](state, payload) {
    state.stepError = payload;
  },
  [types.SET_BENEFICIARY](state, payload) {
    state.beneficiary = payload;
  },
  [types.SET_BENEFICIARY_ERROR](state, payload) {
    state.sponsorSlugError = payload;
  },
  [types.SET_INPUTS](state, payload) {
    state.inputs = payload;
  },
  [types.RESET_AUTHORIZATION_STATE](state) {
    Object.assign(state, {
      files: [],
      inputs: [],
      sponsorSlugError: null,
      currentForm: null,
      claimType: null,
      currentCoverage: null,
      error: {},
      beneficiary: {},
      stepError: false,
      searchBeneficiaries: {
        sponsorForms: [],
        beneficiaries: [],
        selectedBeneficiary: null,
        benefits: null,
        searchCompleted: false,
      },
    });
  },
  [types.SET_REIMBURSEMENT_STATUS](state, payload) {
    state.reimbursementStatus = payload;
  },
  [types.SET_CLAIM_TYPE](state, payload) {
    state.claimType = payload;
  },
  [types.SET_LIST_AUTHORIZATION](state, payload) {
    state.listAuthorization = payload;
  },
  [types.SET_AUTHORIZATION](state, payload) {
    state.authorization = payload;
  },
  [types.SET_AUTHORIZATION_ERROR](state, payload) {
    state.authorizationError = payload;
  },
  [types.SET_SPONSOR_SLUG](state, payload) {
    state.sponsorSlug = payload;
  },
  [types.SET_IS_REIMBURSEMENT](state, payload) {
    state.isReimbursement = payload;
  },
  [types.SET_COUNTRY_CODE](state, payload) {
    state.countryCode = payload;
  },
  [types.SET_CURRENCY_CODE](state, payload) {
    state.currencyCode = payload;
  },
  [types.SET_LOADING_FILE](state, payload) {
    state.loadingFile = payload;
  },
  [types.SET_SEARCH_BENEFICIARY_SPONSOR_FORMS](state, payload) {
    state.searchBeneficiaries.sponsorForms = payload;
  },
  [types.SET_SEARCH_BENEFICIARIES_RESULTS](state, payload) {
    state.searchBeneficiaries.beneficiaries = payload;
  },
  [types.SET_BENEFICIARY_INFORMATION](state, payload) {
    state.beneficiaryInformation = payload;
  },
  [types.SET_SELECTED_BENEFICIARY](state, payload) {
    state.searchBeneficiaries.selectedBeneficiary = payload;
  },
  [types.SET_BENEFICIARY_SEARCH_COMPLETED](state, payload) {
    state.searchBeneficiaries.searchCompleted = payload;
  },
  [types.SET_SPONSOR_CONFIGURATION](state, payload) {
    state.sponsorConfiguration = payload
  }
};
