import Vue from 'vue';
import URI from 'urijs'
import vuei18n from '@/plugins/i18n';
import { config } from '@/plugins/env.config'
import { statusConfiguration } from '@/config/beneficiary'
import * as arcService from '@/services/v2/arc-api-v2.service'

async function retryRequest(error, attempt, callback, ...callbackArgs) {
  const maxAttempts = config.getTokenMaxAttempts
  const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

  const response = error.response ? error.response : error
  const status = response.status ? response.status : error.status

  if (status === 500 && attempt < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return await callback(...callbackArgs, attempt + 1)
  }

  throw error
}

export async function getBeneficiary(beneficiaryId, attemptNumber = 1) {
  const getSponsorUri = new URI(config.apiBaseUrl)
  getSponsorUri.segment('plans')
  getSponsorUri.segment('v1')
  getSponsorUri.segment('beneficiaries')
  getSponsorUri.segment(beneficiaryId.toString())

  try{
    return await Vue.prototype.$http.get(getSponsorUri.toString())
  }catch(error) {
    return retryRequest(error, attemptNumber, getBeneficiary, beneficiaryId)
  }
}

export function getStatusConfiguration(status) {
  return statusConfiguration
  .find(config => config.status === status);
}

export function getGeneralBalanceData(payload) {
  const { type, total, availableLabel, availableColor, available,
  usedLabel, usedAmount, usedColor } = payload;

  let usedFixedAmount = 0

  if(usedAmount > 0 && total > 0){
    usedFixedAmount = ((usedAmount / total) * 100).toFixed(1);
  }

  if (type === 'bar') {
    return {
      datasets: [

        {
          label: usedLabel.concat(' (', usedFixedAmount, '%)') ,
          backgroundColor: usedColor,
          data: [usedAmount],
          barThickness: 5
        },
        {
          label: availableLabel,
          backgroundColor: availableColor,
          data: [available],
          barThickness: 5
        },
      ]
    }
  } else if (type === 'doughnut') {
    return {
      datasets: [
        {
          data: [available, usedAmount],
          backgroundColor: [availableColor, usedColor],
          borderWidth: 0,
        }
      ],
      labels: [availableLabel, usedLabel],
      doughnutLabel: ((usedAmount / total) * 100).toFixed(0) + '%',
    }
  } else {
    return {}
  }
}

export function getDefaultDetailDatasets() {
  return [
    {
      label: vuei18n.t('remanentDetail.modal.graph.used'),
      backgroundColor: [],
      data: [],
      barThickness: 20
    },
    {
      label: vuei18n.t('remanentDetail.modal.graph.available'),
      backgroundColor: [],
      data: [],
      barThickness: 20
    },
  ]
}

export function getDetailData(labels, datasets, detailInformation) {
  detailInformation.forEach(coverageBalance => {
    labels.push(vuei18n.t(arcService.getType(coverageBalance.coverageType)));

    datasets[0].data.push(coverageBalance.maximumBenefit - coverageBalance.availableBenefit);
    datasets[0].backgroundColor.push(this.getStatusConfiguration('USED').color)

    datasets[1].data.push(coverageBalance.availableBenefit);
    datasets[1].backgroundColor.push(this.getStatusConfiguration('AVAILABLE').color)
  })
}

export async function getBeneficiaryBenefits (beneficiaryId, attemptNumber = 1) {
  const getBeneficiaryBenefitsUri = new URI(config.apiBaseUrl)
  getBeneficiaryBenefitsUri.segment('plans')
  getBeneficiaryBenefitsUri.segment('v1')
  getBeneficiaryBenefitsUri.segment('beneficiaries')
  getBeneficiaryBenefitsUri.segment(beneficiaryId.toString())
  getBeneficiaryBenefitsUri.segment('benefits')

  try{
    return await Vue.prototype.$http.get(getBeneficiaryBenefitsUri.toString())
  }catch(error) {
    return retryRequest(error, attemptNumber, getBeneficiaryBenefits, beneficiaryId)
  }
}
