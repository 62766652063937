<template>
  <v-overlay :value="loaderHandler" color="white">
    <v-row>
      <v-col cols="12" class="text-center">
        <v-progress-circular size="70" width="5" color="primary" indeterminate>
        </v-progress-circular>
      </v-col>
    </v-row>
  </v-overlay>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const general = createNamespacedHelpers('general')

export default {
  name: 'Loader',

  computed: {
    ...general.mapGetters(['loaderHandler'])

  }
}
</script>
