import auth from './auth';
import authorization from './authorization'
import agreements from './agreements'
import general from './general'
import authorizationV2 from './authorizationV2';

export default {
  auth,
  authorization,
  agreements,
  general,
  authorizationV2
}