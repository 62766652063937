import * as moment from 'moment';

const validateDateFormat = ({ value }) => {
    const date = parseDate({ stringDate: value });
    if(isNaN(date)) {
        return false;
    }

    return true
}

const validateDate = ({ value }) => {
    const date = parseDate({ stringDate: value });
    if(isNaN(date)) {
        return false;
    }

    return date > new Date(1900, 0, 1) && date < new Date();
}

const validateIsDateSameOrAfter = ({value}) => {
    const date = parseDate({ stringDate: value })
    if(isNaN(date)){
        return false
    }

    const now = moment().format("YYYY-MM-DD");

    return moment(date).isSameOrAfter(now)
}

const getDateValues = (input) => {
    const value = input.replace(/\D/g, "");

    const values = [];
    const day = value.substr(0, 2);

    if (day) {
        values.push(day);
    }

    if (value.length > 2) {
        values.push(value.substr(2, 2));
    }

    if (value.length > 4) {
        values.push(value.substr(4, 4));
    }

    return values;
}

const parseDate = ({ stringDate }) => {
    if(!stringDate) {
        return NaN;
    }

    if(stringDate.length == 10) {
        const parsedDate = moment(stringDate, 'DD/MM/YYYY');
        return parsedDate.toDate();
    }

    return NaN;
}

const formatDate = ({stringDate}) => {
    const date = parseDate({stringDate});
    return moment(date).format('YYYY-MM-DD');
}

const parseDateFromSearch = ({ stringDate }) => {
    if(!stringDate) {
        return NaN;
    }

    if(stringDate.length == 10) {
        const parsedDate = moment(stringDate, 'YYYY-MM-DD');
        return parsedDate.toDate();
    }

    return NaN;
}

const formatDateFromSearch = ({stringDate}) => {
    const date = parseDateFromSearch({stringDate});
    return moment(date).format('DD/MM/YYYY');
}

const validateIsDateSameOrBefore = (date1, date2) => {

    const date1Parse = parseDate({ stringDate: date1 });
    const date2Parse = parseDate({ stringDate: date2 });

    return moment(date1Parse).isSameOrBefore(date2Parse)
}

export { validateDate, getDateValues, formatDate, parseDate, formatDateFromSearch, parseDateFromSearch, validateIsDateSameOrBefore, validateIsDateSameOrAfter, validateDateFormat }