import {
  SHOW_LOADER,
  CONFIGURE_ALERT,
  CONFIGURE_SNACKBAR,
  SET_FILTERS
} from './mutation-types'

const showLoader = ({commit}, payload) => {
  commit(SHOW_LOADER, payload)
}

const configureAlert = ({commit}, payload) => {
  commit(CONFIGURE_ALERT, payload)
}

const configureSnackbar = ({ commit }, payload) => {
  commit(CONFIGURE_SNACKBAR, payload)
}

const setFilter = ({ commit }, payload) => {
  commit(SET_FILTERS, payload)
}

const removeFilter = ({ commit, state }, payload) => {
  const inputs = state.filters;
  const inputsFiltered = inputs.filter(input => input.slug != payload)
  commit(SET_FILTERS, inputsFiltered)
}

export default {
  showLoader,
  configureAlert,
  configureSnackbar,
  setFilter,
  removeFilter
}
