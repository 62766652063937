import agreementService from '@/services/agreement.service'
import router from '@/router'
import {
  SET_PENDING_AGREEMENT_DOCUMENTS,
  SET_PENDING_HTML_DOCUMENTS,
  SET_ERROR_AGREEMENT
} from '../mutation-types'

async function validateAgreementDocuments ({ commit }) {
  const personaAttributes = this.getters['auth/beneficiaryAttributes']
  const countryCode = this.getters['authorization/beneficiaryCountryCode']
  const owner = this.getters['authorization/sponsorSlug']
  try {
    const agreementDocuments = await agreementService.searchAgreementDocuments(
      countryCode, personaAttributes, owner
    )
    const pendingAgreementDocuments = agreementDocuments.filter(agreementDocument => !agreementDocument.accepted)

    commit(SET_PENDING_AGREEMENT_DOCUMENTS, pendingAgreementDocuments)
    localStorage.setItem('agreementDocuments', JSON.stringify(pendingAgreementDocuments))
  } catch (error) {
    return
  }
}

async function acceptLegalAgreementDocuments ({ commit, state, dispatch }, beneficiaryAttributes) {
  try {
    const promises = state.pendingAgreementDocuments.map((pendingDocument) =>
      agreementService.acceptDocument(
        pendingDocument,
        beneficiaryAttributes
      )
    );
    await Promise.all(promises);
    commit(SET_PENDING_AGREEMENT_DOCUMENTS, [])
    localStorage.removeItem('agreementDocuments')
    router.push('/home')
  } catch (error) {
    dispatch('setError',{ type: 'error', message: 'globalErrorUnknown'})
  }
}

const setPendingAgreementDocuments = ({ commit }, payload) => {
  commit(SET_PENDING_AGREEMENT_DOCUMENTS, payload)
}

const setPendingHtmlDocuments = ({ commit }, payload) => {
  commit(SET_PENDING_HTML_DOCUMENTS, payload)
}

const setError = ({ commit }, payload) => {
  commit(SET_ERROR_AGREEMENT, payload)
  router.push('/login')
}

const resetError = ({ commit }) => {
  commit(SET_ERROR_AGREEMENT, {})
}

export default {
  validateAgreementDocuments,
  setPendingHtmlDocuments,
  acceptLegalAgreementDocuments,
  setError,
  resetError,
  setPendingAgreementDocuments
}