<template>
  <v-app>
    <Loader />
    <router-view/>
  </v-app>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { config } from '@/plugins/env.config'
import Loader from './components/loader/Loader.vue';

const authModule = createNamespacedHelpers('auth');

export default {
  name: 'App',

  components: {
    Loader
  },

  beforeCreate(){
    const favicon = document.getElementById("favicon")

    if(config.theme === 'METLIFE'){
      favicon.href = process.env.BASE_URL+"favicon-metlife.ico"
    }
    else{
      favicon.href = process.env.BASE_URL+"favicon.ico"
    }
  },

  mounted() {
    if(this.webTitle){
      document.title = this.webTitle
    }
  },

  computed: {
    ...authModule.mapGetters(['webTitle'])
  }
};
</script>
