import { isNavigationFailure } from "vue-router"

export const safeNavigation = (failure) => {
  if(!failure){
    return;
  }

  try {
    const isFailure = isNavigationFailure(failure)

    if(isFailure){
      console.warn('Navigation error', failure)
      return;
    }

  }catch(error){
    console.warn('Unrecognized navigation error')
    return;
  }

  Promise.reject(failure)
}