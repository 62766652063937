import Vue from "vue";
import Vuetify from "vuetify/lib";
import InstagramIcon from "../components/svg/InstagramIcon.vue";
import LinkedInIcon from "../components/svg/LinkedInIcon.vue";
import WebsiteIcon from "../components/svg/WebsiteIcon.vue";
import ChatIcon from "../components/svg/ChatIcon";
import OsiguLogo from "../components/svg/OsiguLogo";
import FileIcon from "../components/svg/FileIcon";
import ErrorIcon from "../components/svg/ErrorIcon";
import MessageCircleIcon from "../components/svg/MessageCircleIcon";
import DiagnosesIcon from "@/components/svg/DiagnosesIcon";
import DrugIcon from "@/components/svg/DrugIcon";
import DiagnosticTestIcon from "@/components/svg/DiagnosticTestIcon";
import LaboratoryIcon from "@/components/svg/LaboratoryIcon";
import MetlifeLogo from "@/components/svg/MetlifeLogo";
import EmptyStateIcon from '@/components/svg/EmptyStateIcon'
import MutuusLogo from "../components/svg/MutuusLogo";
import { config } from "@/plugins/env.config";


let vuetifyInstance;

if (config.theme) {
  if (config.theme === "OSIGU") {
    const primaryColor = "#528ef7";
    const secondaryColor = "#1f3261";
    const errorColor = "#FF726D";

    vuetifyInstance = new Vuetify({
      theme: {
        options: {
          customProperties: true,
        },
        themes: {
          light: {
            primary: primaryColor,
            secondary: secondaryColor,
            error: errorColor,
            accent: errorColor,
            "osg-gray": "#828282",
            "osg-black": "#2a2a2a",
            "osg-gray-comment": "#f4f5f7",
            "osg-blue-comment": "#e0f2ff",
            "osg-text-message": "#474747",
            "osg-ambar-warning": "#f3bf58",
            "osg-gray-1": "#474747",
            "osg-gray-2": "#ADADAD",
            "osg-gray-3": "#7a7a7a",
            "osg-gray-4": "#f2f2f2"
          },
        },
      },
      icons: {
        values: {
          "osg-instagram": {
            component: InstagramIcon,
          },
          "osg-linkedin": {
            component: LinkedInIcon,
          },
          "osg-website": {
            component: WebsiteIcon,
          },
          "osg-chat": {
            component: ChatIcon,
          },
          "osg-logo": {
            component: OsiguLogo,
          },
          "osg-file": {
            component: FileIcon,
          },
          "osg-error": {
            component: ErrorIcon,
          },
          "osg-message-circle": {
            component: MessageCircleIcon,
          },
          "osg-diagnoses-icon": {
            component: DiagnosesIcon,
          },
          "osg-drug-icon": {
            component: DrugIcon,
          },
          "osg-laboratory-icon": {
            component: LaboratoryIcon,
          },
          "osg-diagnostic-test-icon": {
            component: DiagnosticTestIcon,
          },
          "osg-empty-state": {
            component: EmptyStateIcon,
          }
        },
      },
    });
  }

  if (config.theme === "MUTUUS") {
    const primaryColor = "#528ef7";
    const secondaryColor = "#1f3261";
    const errorColor = "#FF726D";

    vuetifyInstance = new Vuetify({
      theme: {
        options: {
          customProperties: true,
        },
        themes: {
          light: {
            primary: primaryColor,
            secondary: secondaryColor,
            error: errorColor,
            accent: errorColor,
            "osg-gray": "#828282",
            "osg-black": "#2a2a2a",
            "osg-gray-comment": "#f4f5f7",
            "osg-blue-comment": "#e0f2ff",
            "osg-text-message": "#474747",
            "osg-ambar-warning": "#f3bf58",
            "osg-gray-1": "#474747",
            "osg-gray-2": "#ADADAD",
            "osg-gray-3": "#7a7a7a",
            "osg-gray-4": "#f2f2f2"
          },
        },
      },
      icons: {
        values: {
          "osg-instagram": {
            component: InstagramIcon,
          },
          "osg-linkedin": {
            component: LinkedInIcon,
          },
          "osg-website": {
            component: WebsiteIcon,
          },
          "osg-chat": {
            component: ChatIcon,
          },
          "osg-logo": {
            component: MutuusLogo,
          },
          "osg-file": {
            component: FileIcon,
          },
          "osg-error": {
            component: ErrorIcon,
          },
          "osg-message-circle": {
            component: MessageCircleIcon,
          },
          "osg-diagnoses-icon": {
            component: DiagnosesIcon,
          },
          "osg-drug-icon": {
            component: DrugIcon,
          },
          "osg-laboratory-icon": {
            component: LaboratoryIcon,
          },
          "osg-diagnostic-test-icon": {
            component: DiagnosticTestIcon,
          },
          "osg-empty-state": {
            component: EmptyStateIcon,
          }
        },
      },
    });
  }

  if (config.theme === "METLIFE") {
    const primaryColor = "#A4CE4E";
    const secondaryColor = "#0090DA";
    const errorColor = "#FF726D";

    vuetifyInstance = new Vuetify({
      theme: {
        options: {
          customProperties: true,
        },
        themes: {
          light: {
            primary: primaryColor,
            secondary: secondaryColor,
            error: errorColor,
            accent: errorColor,
            "osg-gray": "#828282",
            "osg-black": "#2a2a2a",
            "osg-gray-comment": "#f4f5f7",
            "osg-blue-comment": "#e0f2ff",
            "osg-text-message": "#474747",
            "osg-ambar-warning": "#f3bf58",
          },
        },
      },
      icons: {
        values: {
          "osg-instagram": {
            component: InstagramIcon,
          },
          "osg-linkedin": {
            component: LinkedInIcon,
          },
          "osg-website": {
            component: WebsiteIcon,
          },
          "osg-chat": {
            component: ChatIcon,
          },
          "osg-logo": {
            component: MetlifeLogo,
          },
          "osg-file": {
            component: FileIcon,
          },
          "osg-error": {
            component: ErrorIcon,
          },
          "osg-message-circle": {
            component: MessageCircleIcon,
          },
          "osg-diagnoses-icon": {
            component: DiagnosesIcon,
          },
          "osg-drug-icon": {
            component: DrugIcon,
          },
          "osg-laboratory-icon": {
            component: LaboratoryIcon,
          },
          "osg-diagnostic-test-icon": {
            component: DiagnosticTestIcon,
          },
        },
      },
    });
  }
}

Vue.use(Vuetify)
export default new Vuetify({...vuetifyInstance})
