<template>
  <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30.6498 36.0001H3.69054C2.92542 36.0001 2.34033 35.4322 2.34033 34.6894V3.58265C2.34033 2.83993 2.92542 2.27197 3.69054 2.27197H30.6498C31.4149 2.27197 32 2.83993 32 3.58265V34.6894C32 35.3885 31.3699 36.0001 30.6498 36.0001Z" fill="#3E8DFF"/>
    <path d="M28.3094 33.7282H1.35021C0.585091 33.7282 0 33.1602 0 32.4175V1.31068C0 0.567961 0.585091 0 1.35021 0H28.3094C29.0745 0 29.6596 0.567961 29.6596 1.31068V32.4175C29.6596 33.1165 29.0295 33.7282 28.3094 33.7282Z" fill="#BBDAFF"/>
    <path d="M28.3094 33.7282H14.8073V0H28.2644C29.0295 0 29.6146 0.567961 29.6146 1.31068V32.4175C29.6596 33.1165 29.0295 33.7282 28.3094 33.7282Z" fill="#8BBBFF"/>
    <path d="M20.4782 24.5098H3.64557V26.0389H20.4782V24.5098Z" fill="#3E8DFF"/>
    <path d="M20.4782 20.9709H3.64557V22.5001H20.4782V20.9709Z" fill="#3E8DFF"/>
    <path d="M20.4782 17.2573H3.64557V18.7865H20.4782V17.2573Z" fill="#3E8DFF"/>
  </svg>
</template>

<script>
export default {
  name: 'FileIcon'
}
</script>