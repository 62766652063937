<template>
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">
    <path
      class="cls-1"
      d="M14.73,9.32h68a10,10,0,0,1,10,10V78.53a10,10,0,0,1-10,10h-68a12,12,0,0,1-12-12V21.32a12,12,0,0,1,12-12Z"
    />
    <path
      class="cls-2"
      d="M12.36,9.31h35a0,0,0,0,1,0,0V88.53a0,0,0,0,1,0,0h-35a10,10,0,0,1-10-10V19.31A10,10,0,0,1,12.36,9.31Z"
    />
    <rect class="cls-3" x="15.45" y="27.99" width="25.48" height="5.23" />
    <rect class="cls-3" x="13.06" y="51.58" width="27.87" height="5.23" />
    <rect class="cls-3" x="22.53" y="62.87" width="18.4" height="5.23" />
    <rect class="cls-3" x="10.81" y="40.17" width="30.13" height="5.23" />
    <rect
      class="cls-3"
      x="54.68"
      y="27.99"
      width="25.48"
      height="5.23"
      transform="translate(134.85 61.22) rotate(-180)"
    />
    <rect
      class="cls-3"
      x="54.68"
      y="51.58"
      width="28.02"
      height="5.23"
      transform="translate(137.38 108.39) rotate(-180)"
    />
    <rect
      class="cls-3"
      x="54.68"
      y="62.87"
      width="18.4"
      height="5.23"
      transform="translate(127.77 130.98) rotate(-180)"
    />
    <rect
      class="cls-3"
      x="54.68"
      y="40.17"
      width="30.13"
      height="5.23"
      transform="translate(139.49 85.58) rotate(-180)"
    />
    <rect
      class="cls-3"
      x="23.22"
      y="46.03"
      width="49"
      height="7.16"
      transform="translate(-1.9 97.33) rotate(-90)"
    />
  </svg>
</template>

<script>
export default {
  name: 'DiagnosticTestsIcon',
  props: {
    color: {
      type: String,
      default: 'primary'
    }
  },
  computed: {
    theme() {
      return this.$vuetify.theme.themes.light;
    }
  }
};
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: #8bbbff;
}

.cls-2 {
  fill: #3e8dff;
}

.cls-3 {
  fill: #bbdaff;
}
</style>