import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { config } from '@/plugins/env.config'

Bugsnag.start({
    apiKey: config.bugsnagApiKey,
    releaseStage: config.bugsnagReleaseStage,
    enabledReleaseStages: config.bugsnagEnabledReleaseStages.split(','),
    plugins: [new BugsnagPluginVue()]
})

const bugnsagVue = Bugsnag.getPlugin('vue')
bugnsagVue.installVueErrorHandler(Vue)