import URI from 'urijs'
import Vue from 'vue';
import { config } from '@/plugins/env.config'
import { status, icons, types } from '@/config/authorization'

async function retryRequest(error, attempt, callback, ...callbackArgs) {
  const maxAttempts = config.getTokenMaxAttempts
  const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

  const response = error.response ? error.response : error
  const status = response.status ? response.status : error.status

  if (status === 500 && attempt < maxAttempts) {
    await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
    return await callback(...callbackArgs, attempt + 1)
  }

  throw error
}

export async function getAuthorizationList(filters, attemptNumber = 1) {
  const getAuthorizationsUri = new URI(config.apiBaseUrl)
    .segment('arc')
    .segment('v1')
    .segment('beneficiaries')
    .segment(filters.beneficiaryId)
    .segment('authorization-summaries')
    .addSearch('from_date', filters.fromDate)
    .addSearch('to_date', filters.toDate)
    .addSearch('statuses', filters.statuses)
    .addSearch('coverages', filters.types)
    .addSearch('page', filters.page)
    .addSearch('size', filters.size)
    .addSearch('search_value', filters.searchValue)
    .addSearch('order_by', filters.orderBy)
    .addSearch('sort', filters.sort)

  try {
    return await Vue.prototype.$http.get(getAuthorizationsUri.toString())
  } catch (error) {
    return retryRequest(error, attemptNumber, getAuthorizationList, filters)
  }
}

export async function getAuthorizationForm(sponsorSlug, attemptNumber = 1) {
  const getAuthorizationFormUri = new URI(config.apiBaseUrl)
    .segment('arc')
    .segment('v1')
    .segment('sponsors')
    .segment(sponsorSlug)
    .segment('authorization-forms')

  try {
    return await Vue.prototype.$http.get(getAuthorizationFormUri.toString())
  } catch (error) {
    return retryRequest(error, attemptNumber, getAuthorizationForm, sponsorSlug)
  }
}

export async function getSponsorConfiguration(sponsorSlug, attemptNumber = 1) {
  const getSponsorConfigurationUri = new URI(config.apiBaseUrl)
    .segment('arc')
    .segment('v1')
    .segment('sponsors')
    .segment(sponsorSlug)
    .segment('configuration')

  try {
    return await Vue.prototype.$http.get(getSponsorConfigurationUri.toString())
  } catch (error) {
    return retryRequest(error, attemptNumber, getSponsorConfiguration, sponsorSlug)
  }
}

export async function sendReimbursement(payload, attemptNumber = 1) {
  const sendReimbursementUri = new URI(config.apiBaseUrl)
    .segment('arc')
    .segment('v1')
    .segment('reimbursements')

  try {
    return await Vue.prototype.$http.post(sendReimbursementUri.toString(), payload)
  } catch(error) {
    return retryRequest(error, attemptNumber, sendReimbursement, payload)
  }
}

export async function sendReimbursementV2(payload, attemptNumber = 1) {
  const sendReimbursementUri = new URI(config.apiBaseUrl)
    .segment('arc')
    .segment('v2')
    .segment('reimbursements')

  try {
    return await Vue.prototype.$http.post(sendReimbursementUri.toString(), payload)
  } catch(error) {
    return retryRequest(error, attemptNumber, sendReimbursementV2, payload)
  }
}

export async function sendAuthorization(payload, attemptNumber = 1) {
  const sendAuthorizationUri = new URI(config.apiBaseUrl)
    .segment('arc')
    .segment('v2')
    .segment('authorizations')

  try {
    return await Vue.prototype.$http.post(sendAuthorizationUri.toString(), payload)
  } catch(error) {
    return retryRequest(error, attemptNumber, sendAuthorization, payload)
  }
}

export async function completeReimbursement(reimbursementId = '', attemptNumber = 1) {
  const completeReimbursementUri = new URI(config.apiBaseUrl)
    .segment('arc')
    .segment('v1')
    .segment('reimbursements')
    .segment(reimbursementId)
    .segment('complete')

  try {
    return await Vue.prototype.$http.post(completeReimbursementUri.toString())
  } catch(error) {
    return retryRequest(error, attemptNumber, completeReimbursement, reimbursementId)
  }
}

export async function completeAuthorization(authorizationId = '', attemptNumber = 1) {
  const completeAuthorizationUri = new URI(config.apiBaseUrl)
    .segment('arc')
    .segment('v2')
    .segment('authorizations')
    .segment(authorizationId.toString())
    .segment('complete')

  try {
    return await Vue.prototype.$http.post(completeAuthorizationUri.toString())
  } catch(error) {
    return retryRequest(error, attemptNumber, completeAuthorization, authorizationId)
  }
}

export async function sendFile(fileSlug, file, reimbursementId = '', attemptNumber = 1) {
  const sendFileFormUri = new URI(config.apiBaseUrl)
    .segment('arc')
    .segment('v1')
    .segment('reimbursements')
    .segment(reimbursementId)
    .segment('inputs')
    .segment(fileSlug)

  const formData = new FormData();
  formData.append('file', file);

  try {
    return await Vue.prototype.$http
      .post(sendFileFormUri.toString(), formData, {})
  } catch(error) {
    return retryRequest(error, attemptNumber, sendFile, fileSlug, file, reimbursementId)
  }
}

export async function sendFileV2(formSlug, fileSlug, file, attemptNumber = 1) {
  const uri = new URI(config.apiBaseUrl)
    .segment('arc')
    .segment('v2')
    .segment('reimbursements')
    .segment('forms')
    .segment(formSlug)
    .segment('inputs')
    .segment(fileSlug)

  const formData = new FormData()
  formData.append('file', file);

  try {
    return await Vue.prototype.$http.post(uri.toString(), formData, {})
  } catch(error) {
    return retryRequest(error, attemptNumber, sendFileV2, formSlug, fileSlug, file)
  }
}

export async function sendAuthorizationFile(fileSlug, file, authorizationId = '', attemptNumber = 1) {
  const sendFileFormUri = new URI(config.apiBaseUrl)
    .segment('arc')
    .segment('v2')
    .segment('authorizations')
    .segment(authorizationId.toString())
    .segment('inputs')
    .segment(fileSlug)

  const formData = new FormData();
  formData.append('file', file);

  try {
    return await Vue.prototype.$http
      .post(sendFileFormUri.toString(), formData, {})
  } catch(error) {
    return retryRequest(error, attemptNumber, sendAuthorizationFile, fileSlug, file, authorizationId)
  }
}

export async function getAuthorization(sponsorSlug, authorizationCode, attemptNumber = 1) {
  const getAuthorizationUri = new URI(config.apiBaseUrl)
    .segment('arc')
    .segment('v2')
    .segment('authorizations')
    .segment('without-claims')
    .segment(authorizationCode)
    .segment('details')
    .addSearch('sponsor_slug', sponsorSlug)

  try {
    return await Vue.prototype.$http.get(getAuthorizationUri.toString())
  } catch(error) {
    return retryRequest(error, attemptNumber, getAuthorization, sponsorSlug, authorizationCode)
  }
}

export async function getAuthorizationFileNotReimbursement(fileId, attemptNumber = 1){
  const getAuthorizationFileUri = new URI(config.apiBaseUrl)
    .segment('arc')
    .segment('v2')
    .segment('authorizations')
    .segment('files')
    .segment(fileId)

  try {
    return await Vue.prototype.$http.get(getAuthorizationFileUri.toString(), {responseType: 'arraybuffer'})
  } catch (error) {
    return retryRequest(error, attemptNumber, getAuthorizationFileNotReimbursement, fileId)
  }
}

export async function getAuthorizationFile(fileId, attemptNumber = 1) {
  const getAuthorizationFileUri = new URI(config.apiBaseUrl)
    .segment('arc')
    .segment('v1')
    .segment('reimbursements')
    .segment('files')
    .segment(fileId)

  try {
    return await Vue.prototype.$http.get(getAuthorizationFileUri.toString(), {responseType: 'arraybuffer'})
  } catch (error) {
    return retryRequest(error, attemptNumber, getAuthorizationFile, fileId)
  }
}

export async function sendComment(authorizationCode, payload, attemptNumber = 1) {
  const sendCommentUri = new URI(config.apiBaseUrl)
    .segment('arc')
    .segment('v1')
    .segment('authorizations')
    .segment(authorizationCode)
    .segment('comment')

  try {
    return await Vue.prototype.$http.post(sendCommentUri.toString(), payload)
  } catch (error) {
    return retryRequest(error, attemptNumber, sendComment, authorizationCode, payload)
  }
}

export function getStatus(authorization) {
  const statusByCoverage = status.find(configuration => configuration.coverageType === authorization.coverageType)

  const authorizationStatus = authorization.status
  const authorizationFormSlug = authorization.authorizationFormSlug || undefined

  if (statusByCoverage) {
    const configurationState = statusByCoverage.states.find(state => {
      const configuredStatus = state.value
      const configuredAuthorizationForm = state.authorizationFormSlug || undefined

      if(authorizationFormSlug){
        return configuredStatus === authorizationStatus && configuredAuthorizationForm === authorizationFormSlug
      }

      return configuredStatus === authorizationStatus
    });

    if(configurationState){
      return configurationState && configurationState.text || '';
    }
  }

  return getStatus({ coverageType: 'DEFAULT', status: authorization.status })
}

export function getType(authorizationType) {
   const configurationType = types.find(type => type.slug === authorizationType);
   return configurationType && configurationType.text || '';
}

export function getTypeObject(authorizationType) {
  const configurationType = types.find(type => type.slug === authorizationType);
  return configurationType;
}

export function getColor(authorization) {
  const statusByCoverage = status.find(configuration => configuration.coverageType === authorization.coverageType)
  if (statusByCoverage) {
    const configurationState = statusByCoverage.states.find(state => state.value === authorization.status);
    return configurationState || {};
  }
  return getColor({ coverageType: 'DEFAULT', status: authorization.status })
}

export function getIcon(productStatus) {
  const configurationState = icons.find(icon => icon.productStatus === productStatus);
  return configurationState ? configurationState : {};
}

export async function setAuthorizationToErrorStatus(authorizationId = '', attemptNumber = 1){
  const errorAuthorizationURI = new URI(config.apiBaseUrl)
    .segment('arc')
    .segment('v2')
    .segment('authorizations')
    .segment(authorizationId.toString())
    .segment('error')

  try {
    return await Vue.prototype.$http.post(errorAuthorizationURI.toString())
  } catch(error) {
    return retryRequest(error, attemptNumber, setAuthorizationToErrorStatus, authorizationId)
  }
}

export async function setReimbursementToErrorStatus(reimbursementId = '', attemptNumber = 1){
  const errorReimbursementUri = new URI(config.apiBaseUrl)
    .segment('arc')
    .segment('v1')
    .segment('reimbursements')
    .segment(reimbursementId)
    .segment('error')

  try{
    return await Vue.prototype.$http.post(errorReimbursementUri.toString())
  }catch(error){
    return retryRequest(error, attemptNumber, setReimbursementToErrorStatus, reimbursementId)
  }
}

export async function getAuthorizationsWithoutClaim(filters, sponsorSlug, attempt = 1) {
  const getAuthorization = new URI(config.apiBaseUrl)
  getAuthorization.segment('arc')
  getAuthorization.segment('v2')
  getAuthorization.segment('authorizations')
  getAuthorization.segment('without-claims')

  if (filters.codeSearchValue) {
    getAuthorization.addSearch('code', filters.codeSearchValue)
  }

  if (filters.coverageType) {
    getAuthorization.addSearch('coverage_type', filters.coverageType)
  }

  if (filters.status) {
    getAuthorization.addSearch('status', filters.status)
  }

  if (filters.dateFrom) {
    getAuthorization.addSearch('date_from', filters.dateFrom)
  }

  if (filters.dateTo) {
    getAuthorization.addSearch('date_to', filters.dateTo)
  }

  if(filters.providerSlug){
    getAuthorization.addSearch('provider_slug', filters.providerSlug)
  }

  if(filters.sort){
    getAuthorization.addSearch('sort', filters.sort)
  }

  getAuthorization.addSearch('sponsor_slug', sponsorSlug)
  getAuthorization.addSearch('page', filters.page)
  getAuthorization.addSearch('size', filters.size)

  try {
    return await Vue.prototype.$http.get(getAuthorization.toString())
  } catch (error) {
    return await retryRequest(error, attempt, getAuthorizationsWithoutClaim, filters, sponsorSlug)
  }
}
